.packagebox{
	height:auto;
	/*background:#f0f;*/
	padding:30px;
}
.packageboxbody{
	/*background:#111!important;*/
	/*background:radial-gradient(circle farthest-corner at center, #333 -7%, #777, #333);*/
	/*background:#ecf0f5!important;*/
	min-height:400px;
}
.singlepackagebox{
	height:auto;
	background:#f190bb;
	border-radius: 5px;
	/*background:radial-gradient(circle farthest-corner at top, #fafafa -30%, #001630, #000);*/
	color:white;
	/*background-image:url("/images/bluegray1.jpeg");
	background-size: 100% 100%;*/
	color:#000;
	padding-bottom:10px;
	margin-top: 30px;
	box-shadow: 0 9px 16px rgba(0,0,0,0.5);
	padding-left: 0px;
	padding-right: 0px;
	/*box-shadow:  0 1px 5px rgba(0,0,0,0.6), 0 0 50px rgba(0,0,0,0.6) inset;*/
	/*box-shadow: 0px 0px 15px  #fff;*/
}
.PackageCheckbox{
	position: absolute;
    right: -8px;
    margin-top: 0px;
}
.pckginfo{
	margin-top: 10px;
}
.pckginfobox{
	background:#fff;
	text-align:center;
	/*color:#999;*/
	border:1px solid #777;
}
.pckginfoboxtext{
	color:#333;
	font-size:10px!important;
	font-family:poppins;
	font-weight: bold;
}
.pckgtitle{
	/*position: absolute;*/
	margin-top: 2px;
	font-size:13px!important;
	font-family:poppins;
}
.pckginfobox1{
	background:#85C1E9  ;
	/*background:#001630;*/
	text-align:center;
	/*color:#999;*/
	border:1px solid #777;
}
.pckginfoboxtext1{
	color:#fff;
	font-size:10px!important;
	font-family:poppins;
	font-weight: bold;
}
.bypackagebtn{
	/*height:150px;*/
	/*border-radius:5px;*/
	/*background-image:url("/images/bluegray1.jpeg");*/
	background-size: 100% 100%;
	background-color: #2980B9;
	/*background: linear-gradient(to bottom, #0099ff 0%, #00ffff 100%);*/
	/*background:#505153;*/
	color:white!important;
	/*background-image:url("/images/pckg3.jpeg");
	background-size: 100% 100%;*/
	/*color:#000;*/
	padding:10px;
	margin-top: 20px;
	box-shadow: 0px 0px 12px  #fff;
}
.boldtextofcost{
	font-weight:bold;
}
.bypackagebtn:hover{
	color:white!important;
}
.packagefontsize{
	font-size:13px;
	/*padding:0!important;*/
	font-family:sans-serif;
	color:white;
}
/*.singlepackagebox:hover{
	cursor:pointer;
	
}*/
.selectpckgicon{
	font-size:25px;
	font-color:#333;
	
}
.selectpckgicongreen{
	color:white!important;
	background: linear-gradient(to bottom, #00ff00 0%, #00ff00 100%);
	font-size:25px;
	height: 22px;
    border-radius: 4px;
    /*border-color:none;*/
	
}
.whitetext{
	color:#ffffff;
	text-align: center;
}
.priceLabel{
	color:#ea3771!important;
	text-align: center;
}
.packagelabel{
	margin-top: 2px;
}
.boldtextofcost h4{
	color:#ea3771!important;
}
.showBox{
	opacity: 1;
	margin-top: 150px;
}
.effect{
		margin-top: 0px;
		opacity: 1;
		transition: all ease-in-out 0.6s;
}
.effect1{
		margin-top: 0px;
		opacity: 1;
		transition: all ease-in-out 0.6s;
}
.effect2{
		margin-top: 0px;
		opacity: 1;
		transition: all ease-in-out 0.6s;
		transition-delay: 0.2s;
}
.effect3{
		margin-top: 0px;
		opacity: 1;
		transition: all ease-in-out 0.6s;
		transition-delay: 0.4s;
}
.effect4{
		margin-top: 0px;
		opacity: 1;
		transition: all ease-in-out 0.6s;
		transition-delay: 0.6s;
}
.examPageWrapbtn{
	padding-bottom:20px;
}
.downloadlogo{
   color:white;
   font-size:20px;
}
.bypackagebtnWrap{
	text-align: center;
	margin-right: 15px;
}
.pckgDescLink{
	color:blue!important;
}
.packageDescPara{
	color:#555!important;
}
.packageDescParaRed{
	color:red!important;
}
.noPadd{
	padding: 0px !important
}

















.nav-pillss > li + li{
	margin-left: 0px !important;
	padding-left: 0px;
    padding-right: 0px;
    font-family: "Poppins","sans-serif";
}
.ReportTabs{
	/*margin-top: 15px;*/
}
.MarginTop20{
	margin-bottom: 25px;
	font-family: "Poppins","sans-serif";
}
.noEarning{
	font-size: 20px;
	font-family: "Poppins","sans-serif";
	color: #333;
}
.reportTables tr th, .reportTables tr td{
	font-family: "Poppins","sans-serif";
}
.categoryTabAdmin{
	margin-top: 30px;
}
.ExamReportTable{
	overflow-x: auto;
}
.searchTableBoxAlignSETReport{
	margin-top: 10px !important;
	margin-bottom: 10px;
	z-index: 111;
}
.reportTitle{
	margin-top: 0px;
}
.searchboxreport{
	margin-top:15px;
	margin-bottom:15px;
	/*margin-left:18px;*/
}
.searchbox{
	margin-top:15px;
	margin-bottom:30px;
	/*margin-left:15px;*/
	/*border-radius:5px;*/
}
.paddingleftzero{
	padding-left: 0!important;
	padding-right: 0!important;
}
.bottommargin{
	margin-bottom:10px;
}
.MarginTop20{
	margin-left:14px;
}
.totalamount{
	color:#333;
	font-weight: bold;

}
.FWSPreport{
	font-weight: bold;
	font-size: 14px;
}
.comFranchTit{
	text-align: Center;
}
.franchCompRepTab{
	margin-top: 5px;
	margin-bottom: 5px;
}
.hidepill{
	display:none!important;
}
.searchbox1{
	margin-top: 0px!important;
    margin-bottom: 15px!important;
}
.bottomSpace{
	margin-bottom:10px;
}
.searchlbl{
	margin-top:25px;
}
.tableAlignment{
	margin-bottom:-20px;
}
.inputTextReportTabel{
	margin-bottom:10px!important;
}
.practiceTextBox{
    padding: 6px 6px!important;
}
.searchlbl1{
	margin-top:10px!important;
}
.searchbxtop{
	margin-top: 9px!important
}
.inputTextpractice{
	margin-bottom:15px!important;
}
.paddingleftnull{
	padding-left:0px!important;
}
.rightspacezero{
	padding-right:0px!important;
}

.studentbluelabel{
	color:blue;
}

.downloadBtnRep{
	position: absolute;
    right: 17px;
    margin-top: 43px;
}
.frachiseNMWrap{
	margin-top: 20px;
}
.frachiseNMWrapRep{
	position: absolute;
    display: none;
}

.franReport{
	margin-bottom: 15px;
}

.tablebottomspace{
	margin-bottom:55px;
}
.searchalignment{
	padding-right:32px!important;
}
.tablealignzero{
	padding-left:0!important;
	padding-right:0!important;
}
.appearingStatus{
	color:green;
}
.attemptedStatus{
	color:red;
}











.companysettingboxinfo1{
    border: 3px solid #d2d6de!important;
    
    min-height: 400px;
}
.companysettingboxs {
	border: 3px solid #d2d6de!important;
    /*margin-left: -50px!important;*/
    min-height:  550px!important;
}
.companysettingboxinfo{
    border: 3px solid #d2d6de!important;

    min-height:  730px!important;
}
.radiobtnnon{
    display: none;
}
.examTypeBtn1{
    margin-top: 23px;
}
.photoscontent{
    /*margin-right: 45px;*/
    margin-top: 10px;
}
.settingbox{
    width:1000px!important;
    }
    .bnktitle{
        padding-bottom: 25px;
    }
    .franchiseid{
    font-size: 13px;
    font-family: Roboto-Regular;
    color: #555;
    font-weight: 600;
    }
.basicinfocmpset{
	/*border: 1px solid #d2d6de;*/
	height: 215px;
    margin-bottom: 15px;
}
.basicinfotxt{
	font-size: 15px !important;
 
    font-weight: 500;
    color: #444444;
    font-family: 'Oswald', sans-serif;
    line-height: 1.1;
	margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 15px;
}
.addcmtset{
	padding:0;
}
.contactcatcls{
    text-align: center;
    margin: 10px;
}
.tbltdfortr{
    width: 100%;

}
.rolelst{
    text-align: center;
   
}
.roletbl{
   text-align: center!important;
  
}
.rolelst1{
    width: 68%;
}
.actionth{
    text-align: left!important;
}
.onlineSXWrapfranchise{
    /*margin-top: 3%;*/
    margin-left: 0px;
}
.roletble1{
   width: 5%;
}
.actiondata{
    width: 15%;
}
.rsimg{
    height: 50px;
    float: right;
} 
.mkpyinpt{
    margin-top: 80px;
}
.btnaligncentr{
    text-align: center;
}
.paybrder{
    border: 3px solid #d2d6de;
    margin-top: 25px;
    width: 85%;
    margin-left: 75px;
    padding-bottom: 25px;
}

.rolesField{
	border-radius: 3px!important;
}
.branchtd{
	width:100px!important;
}
.companysettingbox{
	min-height:455px!important;
}
.companysettingbox{
	/*width:725px!important;*/
}
.box-body{
    padding-top:30px!important;
    }

.editbtns2{
    padding:0px 0px!important;
}
.roletbl{
	text-align: center!important;
}
.editbtns{
	padding:10px 10px!important;
}
.editbtnshvr:hover{
background:#00b8ff!important;
border-color:#00b8ff!important;
color:#fff!important;
}
.editbtns1{
	padding:5px 5px!important;
}
.editbtns2{
	padding:5px 5px!important;
}
.addrolesBtnaddroll{
	margin-top: 0px!important;
}
.rolelst{
	text-align: center;

}
.CategoriesListUpload {
    font-size: 20px;
    text-align: center;
}
.myTable{
	border:1px solid #d3d6de;
}
.contentheight{
    min-height:990px!important;
    background:#ecf0f5;
    }
    .editInfo,.editTax{
        height: 29px!important;
        line-height: 16px!important;
    }
    .companysettingbox{
        border:3px solid #d2d6de!important;
        /*margin-left:10px;*/
    }
/*
________________ For Table_____________________*/
/**/

/* .CStabl {
    height:25px!important;
    text-align: left!important;

    padding-top:12px;
}
.CStablst{
    padding:10px 15px !important;	
} */

.basicinfocmpset1{
  background: #fff;
  height: 1200px;
  padding:15px;
  margin: 20px;

}
.franchiseidpad{
    padding-left:  0px;
}
.franchiseidbox{
    /*padding-right: 0px!important;*/
    margin-top: -20px!important;
}
.actions{
    padding:0px!important;
}

.chosefilebor{
    border: 1px solid #ccc;
    padding: 5px;
    margin: 0px;
    /*margin-left: 10px;*/
}
.instructionWrap{
    white-space: pre-line;
    font-family: "Roboto-Regular";
    color: #333333;
    font-size: 16px;
    margin-bottom: 30px;
    height: auto;
}
.instructionWrap1{
    margin-bottom: 25px;
    margin-top: 10px;
}
.franchiseBoxheader{
    /*padding-left:40px;*/
}
.franchiseBoxheader2{
    padding-left:28px;
}
.imagetype{
    padding-right: 18px!important;
}
.imagelabel{
    font-size: 11px!important;
}
.documentModal{
    height: 500px;
    /*overflow-y: auto;*/
}
.docLabel{
    color:blue;
}
.docLabelhover:hover{
    cursor:pointer;
}
.progressBarAlign{
    margin-top:-50px!important;
}
.dwnldLabel{
    color:blue;
    font-size:14px;
}
.docImageView{
    height:100%;
    width:100%;
    text-align: justify;
}
.progressbarPadding{
    margin-top:0px!important;
}
.verificationLbl{
    font-size:14px!important;
    color:#3c8dbc;
}
.doclblalign{
    margin-left:25px;
}
.modalbodyHeight{
    height:400px;
}
.docVerified{
    font-weight: bold;
}
.docVerifiedDisabled{
    font-weight: bold;
    cursor: not-allowed;
}
.notVerifiedTxt span{
    /*background: #;*/
    padding: 2px 5px;
}
.docFranWrap{
    padding: 0px;
}
.docBox{
    padding-top:80px!important;
}
.prevPage{
    padding-top:10px!important;
    padding-bottom:10px!important;
}
.noPadC{
    padding:0 !important;
}
.htSame{
    height: 66px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.seleectQueInput {
    margin-top: 0px !important;
}

@media (max-width: 767px){
	.packagebox{
		padding:0px;
	}
	.packagefontsize {
	    padding: 7px 0px;
	}
}