.testtitle{
	color:#1d109d;
	font-weight: bold;
	font-size:14px;
}
.testtitlepadding{
	padding-right:0!important;
}
.practicetesttitle{
	color:#333!important;
	font-size:14px;
	margin-top: 5px!important;
    margin-bottom: 5px!important;
}
.leftpaddingNone{
	padding-left:0!important;
}
.practicetesttitle1{
	color:#333!important;
	font-size:14px;
	margin-top: 5px!important;
    margin-bottom: 5px!important;
    padding-left:40px;
}
.bluetext{
	color:#1d109d;
	font-weight: bold;
}
.bullet{
	color:#333!important;
	font-size:10px!important;
}
.startexambtn{
	color: #1d109d!important;
    background: none!important;
    text-decoration-line: underline!important;
    padding-top:0!important;
    font-size:15px;
    font-weight: bold;
}
.startexambtn1{
	color: #1d109d!important;
    background: none!important;
    text-decoration-line: underline!important;
    padding-top:0!important;
    font-size:16px;
    font-weight: bold;
    margin-top:6px;
}
.pEListTableScroll{
	overflow-y: auto;
	height:80px;
}
.paddingnone{
	padding:0!important;
}
.examlinks1{
	margin-top: 60px;
	text-align: center;
}
.leftmarginexam{
	margin-left:10px;
}
.partitionline{
	border-top:2px solid gray;
}

.redtext{
	color:red;
}
.qpTestDate{
	font-size:14px!important;
	padding-left:0!important;
	padding-right:0!important;
}
.aCategoryWiseExam a,.aCategoryWiseExam{
	color: #3c8dbc;
	cursor: pointer;
}
.aCategoryWiseExam{
	margin-right: 5px;
}
.tdlastColmn{
	padding: 0px !important;
}
.startExambox{
	border:1px solid #7a7a7a;
	box-shadow: 0px 5px 10px 2px;
    padding: 20px;
}
.modalExamContent{
	width: 60%;
}
.createExamWrapp{
	margin-top: 1% !important;
}
.createExambox{
	height:15px;
}
.studProfileTit23{
    font-size: 16px;
    padding: 0px;
    margin-top: 4px;
    margin-left: 9px;
    font-weight: bold;
    font-family: 'Roboto-Regular';
    color: #333;
}
.purchasedlabel{
	font-size:14px!important;
}
.finishText{
	color:white;
}
.answerOption{
	height:30px;
	padding: 0px 35px;
}
.sliderTitles{
	padding-bottom:15px!important;
}

.startExambox1{
	margin-top:-50px;
}
.content-wrapperexampaper1,.content-wrapperexampaperstudent{
	padding-bottom:80px!important;
}
.payModalBody{
	margin-top: 15px;
	height: 200px;
}
.RCPBtn{
	text-align: right;
}
.modalBodyPayRec{
	min-height: 250px;
}
.payCompSelect{
	margin-top: 15px;
    /*margin-bottom: 30px;*/
}
#mySlideShowStartExam{
	height: 390px;
	padding-top: 1.5%;
	margin-top: 0.9%;
	border: 13px solid transparent;
	color: #fff;
	border-radius: 16px;
}
#mySlideShowStartExam{
    border-image:url("/images/imagestexture.jpeg") 30 round;
    background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
    background-color: #010101;
    background-size: 16px 16px;
}
.poorSec{
	margin-top: 0px;
}
.examCaptureImg{
	height: 40px;
	width: 40px;
}
.wrProcessing{
	display: none;
	margin: auto;
}
.itemCustomT{
	transition: transform .3s ease-in-out !important;
}
.marg38{
	margin-top: 38px
}
/**************************For PracticeStartExam.js***********************/
.qpRow{
    padding: 10px;
    clear: both;
}
.qpTestTitle{
    font-size: 18px;
    font-family: "Poppins","sans-serif";
    color:#333!important;
}
