.mcqsection{
	margin-top: 5%;
	color: #FFFFFF;
	font-size: 16px;
}
.question{
	color: #ffffff;
}
.exambox{
	min-height: 360px;
    /* height: 360px; */
    padding-top: 1.5%;
    margin-top: 0.9%;
    border: 13px solid transparent;
    color: #fff;
    border-radius: 16px;
    border-image: url(/images/imagestexture.jpeg) 30 round;
    background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
    background-color: #010101;
    background-size: 16px 16px;
    padding-left: 20px;
    padding-right: 20px;
}
.questionnumber{
	bottom: -140px;
    left: 0px !important;
    margin-left: 0% !important;
    width: 100% !important;
    height: 102px;
    z-index: 15;
    top: 107%;
}
.questionnumber li{
	line-height: 14.5px;
    margin-bottom: 2px;
    font-size: 9px;
    font-family: "Poppins","sans-serief";
	background: transparent;
    color: #fff ;
    width: 18px;
    height: 18px;
    padding: 2px;
    margin: 0px 4px;
    border-radius: 100%;
    text-indent: 0;
}

.questionnumber li.active{
	font-size: 10px;
	background: #fff !important;
    color: #000 !important;
    width: 18px;
    height: 18px;
    padding: 2px;
    margin: 0px 4px;
    border-radius: 100%;
    text-indent: 0;
}
.carousel-control-prev{
	position: absolute;
	left: 30px;
	vertical-align: middle;
	top:45%;
}
.carousel-control-next{
	position: absolute;
	right: 50px;
	vertical-align: middle;
	top:45%;
}
.qusetionanssection{
	width: 100%;
	margin: auto;
	height: auto;
	
}
.answeroption {
    margin-left: 14px;
    font-size: 20px;
    margin-top: 0px;
    font-family: Roboto-Regular;
    position: absolute;
    color: #ffffff;
}
.carousel-control-next-icon, .carousel-control-prev-icon{
	margin-top: -10px;
    font-size: 30px;	
}

.examcheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #333333;
    border: 1px solid #006622;
    box-shadow: 0px 0px 5px #006622;
}    
.answerinputopions{
	height: 30px;
    padding: 0px 35px;
}

.answerbottom{
	margin-bottom: 0px;
}

.answerinputopions .checkmarkk {
    color: #FFFFFF;
}

.answerinputopions input[type=radio]:checked ~ span {
    color: #FFFFFF;
}

.checkedanswer {
    background-color: #006622 !important;
    box-shadow: none !important;
    color: #4183D7 !important;
}

.checkedanswer:after {
    display: block !important;
}

.checkedanswer:after {
    left: 10px;
    top: 3px;
    width: 8px;
    height: 18px;
    border: 1px solid #fff;
    border-width: 0 2.2px 2.2px 0;
    transform: rotate(45deg);
}

/*input[type=radio]:checked ~ span {
    color: #4183D7;
}*/

.greenClor {
    background: #006622 !important;
    color: #fff !important;
    border: 1px solid #006622 !important;
}

input[type=checkbox], input[type=radio] {
    margin: 23px 0 0;
}

.containerr input {
    /* position: absolute; */
    opacity: 0;
    cursor: pointer;
    /* height: 123px; */
    height: 20px;
    width: 20px;
}

.qusetionanssection > .item {
    
    -webkit-transition: 0.0s ease-in-out left;
    -moz-transition: 0.0s ease-in-out left;
    -o-transition: 0.0s ease-in-out left;
    transition: 0.0s ease-in-out left;
    padding: 30px 60px;
    min-height: 340px;
}

.noPadding{
	padding: 0px !important;
}

.finishexamlibtn{
    background: #2E86C1 !important;
    width: 7% !important;
    border-radius: 0px !important;
    /* border: 1px solid #2E86C1 !important; */
    padding: 2px 0px !important;
    font-size: 14px !important;
    font-family: "Poppins","sans-serif";
    margin-top: 4px !important;
    position: absolute;
}
.addspaceitem{
    height: 30px;
}

.answerinputopions .containerr input:checked ~ .checkmarkk:after {
    /*display: none;*/
}

.containerr input:checked ~ .checkmarkk > .checknewicon {
    display: block;
    left: 10px;
    top: 3px;
    width: 8px;
    height: 18px;
    border: 1px solid #fff;
    border-width: 0 2.2px 2.2px 0;
    transform: rotate(45deg);
    position: absolute;
}

.answerinputopions .containerr input:checked ~ .checkmarkk {
    /*background-color: #333333;;*/
    /*box-shadow: none;*/
    box-shadow: none;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    /* height: 18px; */
    border: 1px solid #fff;
    border-width: 0px;
    transform: none
}
.mainmcqall{
    padding-bottom: 120px !important;
}


@media (min-width: 768px) {
.makespancenter {
    width: auto;
    float: none;
    display: inline;
}
.examDetailsWrap1 {
    margin-left: -10px; 
}

.examDetailsWrap {
    padding: 10px 15px;
    margin-bottom: 2%;
    text-align: center;
}

.makespancentercounter {
    position: absolute;
    top: -20px;
}
}

@media (max-width: 767px) {
    .mainmcqall{
        padding-right: 0px !important;
    }
    .mcqsection{
        padding: 0px;
    }
    .mainexammcqsection{
        margin-top: 10%;
        color: #FFFFFF;
        font-size: 16px;
    }
    .qusetionanssection > .item {
        width: 400px;
        padding-left: 2px;
    }
    .glyphicon-chevron-left, .glyphicon-chevron-right{
        width: 30px !important;
        height: 30px !important;
    }
    .carousel-control-prev {
        left: 5px;
    }
    .carousel-control-next {
        right: 5px;
    }
    .carousel-control-next-icon, .carousel-control-prev-icon {
        margin-top: -10px;
        font-size: 22px;
    }
    .answerinputopions {
        height: 30px;
        padding: 0px 10px;
    }
    .quesnumber{
        font-size: 24px;
    }
    .questionTitWrapp{
        font-size: 24px;
    }
    .mainmcqall {
        padding-bottom: 330px !important;
    }
    .questionnumber li {
        line-height: 21.5px;
        width: 22px;
        height: 22px;
        margin: 4px 4px;
        font-size: 11px;
    }
    .questionnumber li.active{
        line-height: 21.5px;
        width: 22px;
        height: 22px;
        margin: 4px 4px;
        font-size: 11px;
    }
   .finishexamlibtn {
        width: 15% !important;
        padding: 1px 5px !important;
        margin-left: -54%!important;
         margin-top: 50px!important;
    }
    .examDetailsWrap {
        padding: 10px 15px;
    }
    .examDetailsWrap3 {
        border-right: 1px solid #555;
    }
    .countdownWrapDiv {
        margin-top: 10px;
        text-align: center;
    }
    .mcqsection .innerWebCam{
        position: relative;
        margin-top: 0px;
    }
    .mainexamcountcss{
        margin-top: 32px;
    }
    
}


@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .qusetionanssection > .item {
        width: 450px;
        padding-left: 10px;        
    }
}
@media(min-width:768px) and (max-width:991px){
    .examDetailsWrap1 {
        margin-left: -36px;
        width: 30%;
        font-size: 10px;
    }
    .examDetailsWrap2{
        width: 30%;
        font-size: 10px;
    }
    .examDetailsWrap3{
        width: 30%;
        font-size: 10px;
    }
    .mySlideShowStartExam{
        margin-top: 50px;
    }
    .exambox{
        margin-top: 52px;
    }
    
    .examDetailsWrap {
        padding: 10px 15px;
        margin-bottom: 2%;
        text-align: center;
    }
    
    .makespancentercounter {
        position: absolute;
        top: 20px;
    }
    .mySlideShowStartExam{
        margin-top: 50px;
    }
    
}
@media (min-width:992px) and (max-width:1200px){
    .finishexamlibtn{
    width: 16%;
    margin-top: 40px!important;
    margin-left: -10%!important;
    }
    .examstdHeight {
        width: 100%;
        height: 396px;
    }
}
