@media (max-width:767px){
    /* .modalcustomclass{
        margin-top: 115px!important;
    } */
    .bgmyprofile{
    	float: left;
    	padding-left: 0px;
	    padding-right: 0px;
	    width: -webkit-fill-available;
    }
    .profileSection12 {
	    background-size: 130% 100%;
	    padding-left: 0px;
	    padding-right: 0px;
	    background-position: -51px;
	}
	.profileSection22{
		background-size: 130% 100%;
	    padding-left: 0px;
	    padding-right: 0px;
	    background-position: -65px;
	}
	.studentdashboardtoppadding {
	    padding-top: 42% !important;
	}
	.studProfileTit3 {
	    margin-top: 0;
	}
	.bgmonkey {
	    background-size: 100% 100%;
	}
	.examlinks1 {
	    margin-top: 67px !important;
	}
	.freePPaper {
	    height: 38px !important;
	    clear: both;
	}
	.dashboardcattable{
		overflow: auto;
	    max-height: 150px;
	    overflow-y: scroll;
	    overflow-x: hidden;

	}
	.reportbdscroll{
		height: auto;
		position: relative;
	}
	::-webkit-scrollbar {
	    -webkit-appearance: none;
	}

	::-webkit-scrollbar:vertical {
	    width: 10px;
	}

	::-webkit-scrollbar:horizontal {
	    height: 10px;
	}

	::-webkit-scrollbar-thumb {
	    background-color: rgba(0, 0, 0, .5);
	    border-radius: 10px;
	    border: 2px solid #ffffff;
	}

	::-webkit-scrollbar-track {
	    border-radius: 10px;  
	    background-color: #ffffff; 
	}
	.customclassmargintopmodal{
		margin-top: 91px!important;
	}
	
}
@media (min-width:768px) and (max-width:991px){
	.bgmyprofile {
		height: 153vh;
	}
	.testtitlepadding {
		padding-right: 0!important;
		margin-left: 57px;
	}
	.startexambtn{
		margin-left: 216px;
		margin-top: -35px;
	} 
	.partitionline {
		border-top: 2px solid gray;
		margin-top: 62px;
	}
	.examtitlecolor {
		color: #1d109d!important;
		margin-top: 14px;
	}
	
}